.card {
  /* border-color: transparent;
  transition: all 1s linear; */
  border: 0.04rem solid rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
}
.card-footer {
  /* background: transparent; */
  /* border-top: transparant; */
  /* transition: all 1s linear; */
  padding: 0 0;
}
.card:hover {
  border: 0.04rem solid rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);
}
.card-footer:hover {
  background: rgba(247, 247, 247);
}
.img-container {
  position: relative;
  overflow: hidden;
}
.img-container:hover .card-img-top {
  transform: scale(1.2);
}
.card-img-top {
  transition: all 1s linear;
  width: 100%;
  height: 10rem;
}
.img-container:hover .card-catimg-top {
  transform: scale(1.2);
}
.card-catimg-top {
  transition: all 1s linear;
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.card-btn-add {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 3px 7px 1px 7px;
  background: var(--lightBlue);
  border: none;
  color: var(--mainWhite);
  font-size: 1.8rem;
  border-radius: 50%;
  /* transform: translate(100%, 100%); */
  /* transition: all 1s linear; */
}
.card-shelf-text {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.card-btn-minus {
  position: absolute;
  bottom: 0;
  right: 45px;
  padding: 0px 5px;
  background: none;
  border-width: 2px;
  border-color: orangered;
  color: orangered;
  font-size: 1.2rem;
  border-radius: 50%;
  /* transform: translate(100%, 100%); */
  /* transition: all 1s linear; */
}
.card-btn-qty {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 1.5rem;
  padding: 0px 15px;
  background: red;
  border: none;
  color: var(--mainWhite);
  border-radius: 50%;
}
.card-btn-out {
  position: absolute;
  bottom: 0px;
  right: 0px;
  background: none;
  border-width: 2px;
  border-color: orange;
  color: orange;
}
.cart-btn:hover {
  color: var(--mainBlue);
  cursor: pointer;
}

.search-label {
  width: 100%;
}
#search-input {
  width: 100px;
}
.map {
  width: 500px;
  height: 300px;
}
