:root {
  --mainBlue: #2a2a72;
  --lightBlue: #009ffd;
  --mainWhite: #f3f3f3;
  --mainDark: #232528;
  --mainYellow: #ffa400;
}

body {
  font-family: 'Roboto', sans-serif !important;
  /* background-color: var(--mainWhite) !important; */
  /* background-color: #7bafd4 !important; */
  /* background-image: url('/bg_Home.jpg'); */
  background-size: cover;
  color: var(--mainDark) !important;
}

.text-blue {
  color: var(--mainBlue);
}
.text-bright {
  color: var(--lightBlue);
}

.btn-black {
  background: transparent;
  text-transform: capitalize;
  font-size: 0.8rem !important;
  color: var(--mainDark);
  border-radius: 0 !important;
  border: 0.1rem solid var(--mainDark) !important;
}
.btn-black:hover {
  background: var(--mainDark) !important;
  color: var(--mainWhite) !important;
}

.form-group.required .control-label:after {
  content: '*';
  color: red;
}
