video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.substitudeBG {
  /* The image used */
  background-image: url('/img/home_bgVideoImg.png');
  /* Full height */
  width: 100vw;
  height: 100vh;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.vheader {
  position: absolute;
  top: 0;
  /* background: rgba(0, 0, 0, 0.5); */
  /* color: #f1f1f1; */
  width: 100%;
  padding: 0px;
}

.vsubheader {
  position: relative;
  height: 10vh;
  /* padding-top: 10vh; */
  /* text-align: center; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.vh1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  /* color: #4a3a27; */
  /* color: #97700e; */
  /* display: block; */
  /* font-size: 3vw; */
  /* letter-spacing: -0.3vw; */
}
.vh1w {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-size: 5vw;
  padding: 6vh;
}

.vsub {
  font-family: 'Roboto', sans-serif;
  /* color: #97700e; */
  /* text-transform: uppercase; */
  /* letter-spacing: 3vw; */
  /* line-height: 1.2; */
  font-size: 2vw;
  text-align: center;
}
.vsubw {
  font-size: 2vw;
  text-align: center;
}

.csel {
  display: flex;
  width: '100px';
  position: relative;
  align-items: center;
  text-align: center;
  justify-content: center;
}

/* vmain {
  display: none;
  width: 80vw;
  left: 10%;
  height: 40vh;
  overflow: auto;
  background: rgba(black, 0.66);
  color: white;
  position: relative;
  padding: 1rem;
} */
