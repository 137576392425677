.card-stripe {
  /* color: #fff; */
  background-color: #6772e5;
  min-width: 365px;
  max-width: 365px;
  /* background-color: #87bbfd; */
  /* border: 5px solid red; */
}

.card-stripe-element {
  color: #fff;
  background-color: #7795f8;
  width: 353px;
  padding: 5px;
  margin: 5px;
  /* background-color: #87bbfd; */
  /* border: 5px solid red; */
}

.stripe-card-element {
  height: 40px;
  display: flex;
  align-items: center;
}
.stripe-element {
  width: 100%;
  padding: 15px;
}
.btn-stripe {
  width: 260px;
  height: 30px;
  line-height: 1;
  border-radius: 5px;
  color: #6772e5;
  background-color: #fff;
  font-weight: bold;
  padding: 5px;
}
.btn-stripe:hover {
  color: #6772e5;
  background-color: gold;
}

.strip-form-input {
  height: 1rem;
  background-color: red;
}
