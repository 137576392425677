.navbar {
  background: #003c71;
  /* background-color: #123b6d; */
  /* background-color: black; */
  opacity: 0.9;
  padding-top: 0px;
  padding-bottom: 0px;
}

.nav-link {
  color: var(--mainWhite) !important;
  font-size: 1rem;
}

/* .dropdown-menu {
  right: 0;
  left: auto;
} */

.badge {
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}
#lblCartCount {
  font-size: 0.8rem;
  background: #ff0000;
  color: #fff;
  padding: 0 5px;
  vertical-align: top;
  margin-left: -1px;
}
